import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import auth from './auth'
import utils from './utils'

import ElementUI from './components/element-ui'
import 'element-ui/lib/theme-chalk/index.css'


import axios from './http'

Vue.use(ElementUI)
Vue.use(auth)

Vue.prototype.$axios = axios
Vue.prototype.$utils = utils

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
