import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)


/**本地测试地址 */
// const URL = 'https://192.168.60.90:8080'
// const AUTHORITY = 'https://192.168.60.99:5000'
/**测试服务器地址 */
const URL = 'https://manufacture.prismlab.com'
const AUTHORITY = 'https://manufacture.prismlab.com:8000'
/**正式服务器地址 */
// const URL = 'https://dashboard.prismlab.com'
// const AUTHORITY = 'https://passport.prismlab.com'

export default new Vuex.Store({
  state: {
    // 认证配置
    username: '未登录',
    role: undefined,
    token: '',
    homeUrl: URL + '/home',
    logoutUrl: URL + '/logout',
    authConfig: {
      authority: AUTHORITY,
      client_id: 'JS_Client',
      client_secret: 'JS_Client_Secrets',
      redirect_uri: URL + '/callback',
      scope: 'openid profile PrismApi Roles offline_access',
      grant_type: 'authorization_code',
      response_type: "code",
      post_logout_redirect_uri: URL + '/logout',
      monitorSession: false
    },
    status: {
      UnPrinted: '未打印',
      Printing: '打印中',
      Error: '打印异常',
      Reworking: '返工申请',
      Suspend: '挂起',
      RefuseRework: '返工被拒绝',
      Printed: '打印完成',
      NoneSubmitted: '未提交',
      NoneReceived: '待接收',
      Received: '已接收',
      Interrupt: '打印中断',
      Cancelled: '已取消',
      PrintCompleted: '打印完成',
      Completed: '订单结束',
      Overdue: '逾期',
      Delaying: '延期申请',
      RefuseDelay: '延期被拒绝',
      Unbound: '未绑定',
      Free: '空闲',
      Disable: '禁用'
    },
    types: {
      UnPrinted: 'info',
      Printing: 'success',
      Error: 'danger',
      Reworking: 'warning',
      Suspend: 'warning',
      RefuseRework: 'error',
      Printed: 'success',
      NoneSubmitted: 'info',
      NoneReceived: 'info',
      Received: 'primary',
      Interrupt: 'danger',
      Cancelled: 'info',
      PrintCompleted: 'success',
      Completed: 'success',
      Overdue: 'warning',
      Delaying: 'warning',
      RefuseDelay: 'error',
      Unbound: 'info',
      Free: 'info',
      Disable: 'danger'
    },
    ids: {
      user: undefined,
      servicer: undefined,
      admin: undefined
    },
  },
  mutations: {
    getUsername(state, name) {
      state.username = name
    },
    setToken(state, val) {
      state.token = val
    },
    setRole(state, role) {
      state.role = role
    },
    setId(state, params) {
      state.ids[params.role] = params.id
    },
    clearIds(state) {
      Object.keys(state.ids).forEach(role => {
        state.ids[role] = undefined
      })
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
