<template>
  <div class="admin-container">
    <a-navbar></a-navbar>
    <el-row>
      <el-col :span="4" style="height: 100%">
        <el-menu :default-active="activeIndex" router>
          <el-menu-item index="/admin/user">
            <i class="el-icon-user"></i>
            <span slot="title">用户列表</span>
          </el-menu-item>
          <el-menu-item index="/admin/servicer">
            <i class="el-icon-printer"></i>
            <span slot="title">服务商列表</span>
          </el-menu-item>
          <!-- <el-menu-item index="/admin/order">
            <i class="el-icon-shopping-cart-1"></i>
            <span slot="title">订单列表</span>
          </el-menu-item> -->
        </el-menu>
      </el-col>
      <el-col :span="20" class="admin-content">
        <router-view></router-view>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import aNavbar from "@/layouts/components/Navbar";
export default {
  data() {
    return {
      activeIndex: this.$route.path,
    };
  },
  components: {
    aNavbar,
  },
};
</script>

<style lang="scss">
$nav-height: 48px;

.admin-container,
.el-row,
.el-col,
.el-menu {
  height: 100%;
  box-sizing: border-box;
}

.admin-container {
  padding-top: $nav-height;
}

.admin-content {
  padding: 1rem 2rem;
  box-sizing: border-box;
}

.a-breadcrumb {
  margin: 1rem 0 3rem;
}

.a-add-btn {
  margin-left: 1rem;
}

.a-table {
  padding: 1rem;
}
</style>
