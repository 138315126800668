<template>
  <div class="a-sign-container">
    <div class="a-nav-container">
      <a-logo></a-logo>
      <div></div>
    </div>
    <div class="sign-container">
      <el-menu :default-active="activeIndex" mode="horizontal" router>
        <!-- <el-menu-item index="/signin">登录 </el-menu-item> -->
        <el-menu-item index="/signup">注册</el-menu-item>
      </el-menu>
      <template>
        <router-view />
      </template>
    </div>
  </div>
</template>

<script>
import aLogo from "@/layouts/components/Logo";
export default {
  data() {
    return {
      // activeIndex: this.$route.path,
    };
  },
  computed: {
    activeIndex() {
      return this.$route.path;
    },
  },
  components: {
    aLogo,
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
$nav-height: 48px;
.a-sign-container {
  height: 100%;
  background: url("../../assets/sign.jpg") no-repeat;
  background-size: cover;
  .a-nav-container {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $nav-height;
    padding: 0 1rem;
    transform: translateX(-50%);
    background-color: transparent;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    .a-logo {
      padding: 0 1rem;
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
  .sign-container {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 500px;
    padding: 1rem;
    border-radius: 3px;
    margin-bottom: 1rem;
    transform: translateX(-50%);
    background-color: #fff;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.18);
    box-sizing: border-box;
  }
}
</style>
