import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Sign from '../views/sign/Index.vue'
import User from '../views/user/Index.vue'
import Servicer from '../views/servicer/Index.vue'
import Admin from '../views/admin/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: false
    }
  },
  /**
   * 登录注册
   */
  {
    path: '/signin',
    name: 'Sign',
    component: Sign,
    children: [
      {
        path: '/',
        name: 'Signin',
        component: () => import('../views/sign/Signin.vue'),
      },
      {
        path: '/signup',
        name: 'Signup',
        component: () => import('../views/sign/Signup.vue'),
      },

    ]
  },
  {
    path: '/tips',
    name: 'Tips',
    component: () => import('../views/sign/Tips'),
  },
  /**
   * 用户
   */
  {
    path: '/user',
    name: 'User',
    component: User,
    children: [
      {
        path: 'model',
        name: 'Model',
        component: () => import('../views/user/Model.vue'),
      },
      {
        path: 'model/:folderId',
        name: 'ModelInfo',
        component: () => import('../views/user/ModelInfo.vue'),
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('../views/user/Order.vue'),
      },
      {
        path: 'order/generate',
        name: 'GenerateOrder',
        component: () => import('../views/user/GenerateOrder.vue'),
      },
      {
        path: 'order/:orderId',
        name: 'OrderInfo',
        component: () => import('../components/OrderInfo/index.vue'),
      }
    ]
  },
  /**
   * 服务商
   */
  {
    path: '/servicer',
    name: 'Servicer',
    component: Servicer,
    children: [
      {
        path: 'printer',
        name: 'Printer',
        component: () => import('../views/servicer/Printer.vue'),
      },
      {
        path: 'Printer/:printerId',
        name: 'PrinterInfo',
        component: () => import('../views/servicer/PrinterInfo.vue'),
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('../views/servicer/Order.vue'),
      },
      {
        path: 'order/:orderId',
        name: 'OrderInfo',
        component: () => import('../components/OrderInfo/index.vue'),
      },
    ]
  },
  /**
   * 管理员
   */
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    children: [
      {
        path: 'user',
        name: 'User',
        component: () => import('../views/admin/User.vue'),
      },
      {
        path: 'servicer',
        name: 'Servicer',
        component: () => import('../views/admin/Servicer.vue'),
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('../views/admin/Order.vue'),
      }
    ]
  },

  {
    path: '/callback',
    name: 'Callback',
    component: () => import('../views/auth/Callback'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/auth/Logout'),
    meta: {
      keepAlive: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
