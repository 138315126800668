import role from './role'
import store from '../store'

function install(Vue, options = {}) {
  Vue.directive(options.name || 'auth', {
    inserted(el, bindding) {
      const value = bindding.value
      let name = store.state.role
      // TODO: 测试使用
      // let name = 'Admin'
      if (typeof value === 'string') {
        if (role.some(role => role.name === name)) {
          let auths = role.filter(role => role.name === name)[0].value
          if (!auths.includes(value)) {
            console.log(store.state.role);
            el.parentNode && el.parentNode.removeChild(el)
          }
        } else {
          el.parentNode && el.parentNode.removeChild(el)
        }
      }
    }
  })
}

export default { install }
