import store from '../store'
import axios from 'axios'
import Oidc from "oidc-client";

function TokenClient(config, tokenData, baseURL) {
  // 日志
  // Oidc.Log.logger = console
  config.userStore = new Oidc.WebStorageStateStore({ store: localStorage })
  // console.log(config);
  this.man = new Oidc.UserManager(config);
  // 添加事件监听
  this.man.events.addUserSignedOut(() => {
    console.log("已经退出！");
    console.log('UserSignedOut：', arguments);
    window.location = store.state.logoutUrl
  });
  this.clientConfig = {
    baseURL: baseURL,
  }
  if (tokenData) {
    this.clientConfig.headers = { 'Authorization': `${tokenData.token_type} ${tokenData.access_token}` }
  }
  this.client = axios.create(this.clientConfig);
}

TokenClient.prototype.request = async function (method, url, data, config) {
  let user = await this.man.getUser()
  if (!user && !window.location.pathname.includes('signup')) {
    await this.man.signinRedirect();
    return;
  }
  config = config || {};
  if (data) {
    config.data = data;
  }
  config.method = method;
  config.url = url;
  let error;
  let result = await this.client.request(config).catch(async err => {
    error = err
    console.log(error);
    if (err.response.status === 401) {
      console.log("%c%s", "color: red", "401");
      let user = await this.man.getUser()
      // debugger
      console.log('user->', user);
      if (user && user.refresh_token) {
        console.log("%c%s", "color: red", "静默登录", user);
        let user = await this.man.signinSilent()
          .catch(async () => {
            console.log("%c%s", "color: red", "静默登录失败");
            await this.man.signinRedirect();
          })
        if (user) {
          console.log("%c%s", "color: red", "静默登录成功", user.refresh_token);

          console.log(store);
          this.StoreUser(user)
          this.clientConfig.headers = { 'Authorization': `${user.token_type} ${user.access_token}` }
          this.client = axios.create(this.clientConfig);
          console.log("%c%s", "color: red", "重新发送请求");
          let resp = await this.client.request(config);
          console.log("%c%s", "color: red", "重新发送请求成功");
          return resp;
        }
      }
      else {
        console.log("%c%s", "color: red", "跳转登录");
        await this.man.signinRedirect();
      }
    }
  })
  return result ? result : { error: error.response.data, code: error.response.status }
}

TokenClient.prototype.RedirectCallback = async function () {
  await this.man.signinRedirectCallback()
    .then(async user => {
      console.log("%c%s", "color: red", "跳转登录成功");
      this.StoreUser(user)
      this.clientConfig.headers = { 'Authorization': `${user.token_type} ${user.access_token}` }
      this.client = axios.create(this.clientConfig);
    })
}

TokenClient.prototype.StoreUser = function (user) {
  store.commit('getUsername', user.profile.name)
  store.commit('setToken', user.access_token)
  store.commit('setRole', user.profile.role)
}

TokenClient.prototype.get = function (url, config) { return this.request("get", url, undefined, config); }
TokenClient.prototype.post = function (url, data, config) { return this.request("post", url, data, config); }
TokenClient.prototype.put = function (url, data, config) { return this.request("put", url, data, config); }
TokenClient.prototype.delete = function (url, config) { return this.request("delete", url, undefined, config); }

export default TokenClient;