<template>
  <div class="a-home-container">
    <a-navbar type="Home"></a-navbar>
    <div id="banner1" class="a-banner-container a-flex-center">
      <div class="banner-left">
        <h1>数据安全增材制造</h1>
        <h1>3D打印云平台</h1>
        <div class="a-btn-group">
          <router-link to="signup?role=user">
            <div class="a-btn">我要打印</div>
          </router-link>
          <router-link to="signup?role=servicer">
            <div class="a-btn">我要加盟</div>
          </router-link>
        </div>
      </div>
      <div class="banner-right">
        <p>
          利用互联网技术，在线3D打印服务可以将三维模型的设计和制作两个过程分离开来，同时又串联在一起。促进了文化创意行业与增材制造行业的分工与合作，从而加速两个行业各自的发展。但是如果不能实现数据安全增材制造，三维模型的数字版权很容易受到侵犯，直接损害了文化创意行业的利益。出于这种顾虑，文化创意衍生品得不到高效快捷的批量化制造，增材制造行业在需求侧受到抑制，又间接制约了增材制造行业的发展。
        </p>
        <p>
          数据安全增材制造系统需要在提供便捷的在线3D打印服务同时，有效的防范三维模型数据泄露和未经授权打印的风险，最大限度的保护三维模型数字版权所有者的合法权益。
        </p>
      </div>
    </div>
    <div id="banner2" class="a-banner-container a-flex-center">
      <div class="banner-item">
        <div class="banner-icon-container">
          <i class="el-icon-refrigerator banner-icon"></i>
        </div>
        <h2 class="banner-title">增材制造</h2>
        <div class="banner-content">
          <p>以三维模型数据为基础，通过材料堆积的方式制造零件或实物的工艺。</p>
          <p>
            增材制造系统中用以完成零件或实物生产过程中一个成形周期的必要组成部分，包括硬件、设备控制软件和设置软件。
          </p>
        </div>
      </div>
      <div class="banner-item">
        <div class="banner-icon-container">
          <i class="el-icon-printer banner-icon"></i>
        </div>
        <h2 class="banner-title">3D打印</h2>
        <div class="banner-content">
          <p>
            利用打印头、喷嘴或其他打印技术，通过材料堆积的方式来制造零件或实物的工艺。此术语通常与增材制造同义。
          </p>
        </div>
      </div>
      <div class="banner-item">
        <div class="banner-icon-container">
          <i class="el-icon-lock banner-icon"></i>
        </div>
        <h2 class="banner-title">数据保护</h2>
        <div class="banner-content">
          <p>使信息不泄露给未授权的个人、实体、进程，或不被其利用的特性。</p>
          <p>采取管理或技术措施，防范未经授权访问数据。</p>
        </div>
      </div>
      <div class="banner-item">
        <div class="banner-icon-container">
          <i class="el-icon-document-checked banner-icon"></i>
        </div>
        <h2 class="banner-title">数据完整性</h2>
        <div class="banner-content">
          <p>数据没有遭受以未授权方式所作的更改或破坏的特性。</p>
        </div>
      </div>
    </div>
    <div id="banner3" class="a-banner-container">
      <div class="banner-content a-flex-center">
        <div class="content-item">
          <h2 class="content-title">模型所有者</h2>
          <div class="content-note">
            <p>
              模型所有者是指从事三维模型设计的公司或个人等数字内容版权所有者，他们通过在云打印平台注册和审核后成为云平台的用户。他们可以向云平台上传希望进行打印的三维模型数据，创建打印订单。模型所有者通过限定订单中各三维模型的打印数量、打印时限，以及限定打印服务商等方式来授权订单的打印生产。
            </p>
          </div>
        </div>
        <div class="content-item">
          <h2 class="content-title">打印服务商</h2>
          <div class="content-note">
            <p>
              打印服务商是指在云打印平台注册并通过审核的具有3D打印生产能力的企业和组织。他们被添加到云平台的打印服务商列表，为模型所有者提供在线3D打印服务。
            </p>
          </div>
        </div>
      </div>
      <div class="a-footer">
        <div class="footer-item info">
          <p class="tel">24小时咨询热线: 400-026-2800</p>
          <p class="address">
            地址：上海市松江区中心路1158号漕河泾科技绿洲11号楼
          </p>
          <p class="copyright">
            © 2021 Prismlab.
            <a href="http://www.beian.miit.gov.cn" target="_blank"
              >沪ICP备15003892号-1</a
            >
          </p>
        </div>
        <div class="footer-item qr-code">
          <img src="https://www.prismlab.com/theme/default/images/ewm.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aNavbar from "@/layouts/components/Navbar";
export default {
  name: "Home",
  data() {
    return {
      offsetTops: undefined,
      wheelCount: 1,
    };
  },
  methods: {
    /**初始化值 */
    WheelInit() {
      this.offsetTops = [
        {
          name: "banner1",
          value: document.getElementById("banner1").offsetTop,
        },
        {
          name: "banner2",
          value: document.getElementById("banner2").offsetTop,
        },
        {
          name: "banner3",
          value: document.getElementById("banner3").offsetTop,
        },
      ];
      document.documentElement.scrollTop = 0;
      window.addEventListener("mousewheel", this.handleMousewheel, false);
      // console.log(this.offsetTops, this.wheelCount);
    },
    handleMousewheel(e) {
      // console.log(e.wheelDelta, e.detail, e);
      /**方向：1-上，0-下 */
      let delta = e.detail ? (e.detail > 0 ? 0 : 1) : e.wheelDelta > 0 ? 1 : 0;
      delta ? this.wheelCount - 1 : this.wheelCount + 1;
      document.documentElement.scrollTop = this.offsetTops[this.wheelCount - 1];

      console.log(delta, document.documentElement.scrollTop);
    },
  },
  components: {
    aNavbar,
  },
};
</script>

<style lang="scss" scoped>
$nav-height: 48px;
.a-flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.a-home-container {
  overflow: hidden;
  .a-banner-container {
    width: 100vw;
    height: 100vh;
    padding: 1rem;
    padding-top: $nav-height;
    box-sizing: border-box;
    &#banner1 {
      background: url("../assets/banner.jpg") no-repeat;
      background-size: cover;
      .banner-left {
        position: relative;
        left: 20%;
        width: 40%;
        transform: translateX(-20%);
        color: #fff;
        .a-btn-group {
          display: flex;
          margin: 2rem 0;
          .a-btn {
            width: 96px;
            height: 48px;
            margin-right: 1rem;
            line-height: 48px;
            text-align: center;
            background-color: #007cfc;
            color: #fff;
            cursor: pointer;
            &:hover {
              background-color: #006ad4;
            }
          }
        }
      }
      .banner-right {
        width: 60%;
        padding: 0 10% 0 20%;
        line-height: 1.8;
        font-size: 1.2rem;
        font-weight: 400;
        color: #fff;
      }
    }
    &#banner2 {
      align-items: flex-start;
      justify-content: space-around;
      background-color: #f5f5f5;
      .banner-item {
        width: 18%;
        margin-top: 20%;
        color: #666;
        .banner-icon-container {
          text-align: center;
          .banner-icon {
            font-size: 108px;
          }
        }
        .banner-title {
          margin: 1rem 0;
          text-align: center;
        }
      }
    }
    &#banner3 {
      position: relative;
      .banner-content {
        margin-top: 18%;
        align-items: flex-start;
        justify-content: space-around;
        .content-item {
          width: 40%;
        }
      }
      .a-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 1rem 1rem;
        background-color: #f5f5f5;
        color: #666;
        box-sizing: border-box;
        .footer-item {
          margin: 0 1rem;
          line-height: 1.8;
          .tel {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
</style>
