/**TODO */
import $store from '../store'
// import TokenClient from './tokenClient_';
/**TODO */
import TokenClient from './tokenClient';

/**本地测试地址 */
// const BASEURL = 'https://localhost:8080/api'
// const BASEURL = 'https://192.168.60.99:5001/api'
// const SESSIONNAME = JSON.parse(localStorage.getItem('oidc.user:https://192.168.60.99:5000:JS_Client'))
/**测试服务器地址 */
const BASEURL = 'https://manufacture.prismlab.com:8001/api'
/**TODO */
const SESSIONNAME = JSON.parse(localStorage.getItem('oidc.user:https://stage.dashboard.prismlab.com:8000:JS_Client'))
/**正式服务器地址 */
// const BASEURL = 'https://dashboard.prismlab.com/api'
// const SESSIONNAME = JSON.parse(localStorage.getItem('oidc.user:https://passport.prismlab.com:JS_Client'))

// let client = new TokenClient(BASEURL);
/**TODO */
let client = new TokenClient(JSON.parse(JSON.stringify($store.state.authConfig)),
  SESSIONNAME,
  BASEURL);

const $axios = {
  client,
  /**获取用户请求 */
  async getCustomer() {
    let result = await client.get(`/customers`)
    return result.error ? result : result.data
  },
  /**获取用户模型文件夹列表请求 */
  async getFolderList(params) {
    let result = await client.get(`/modelfilefolder`, { params })
    return result.error ? result : result.data
  },
  /**获取用户模型文件模型请求 */
  async getModelOfFolder(params) {
    let result = await client.get(`/modelfilefolder/${params.folderId}/models`, { params })
    return result.error ? result : result.data
  },
  /**删除用户模型文件夹模型请求 */
  async deleteFolder(params) {
    let result = await client.delete(`/printmodels`, { params })
    return result.error ? result : result.data
  },
  /**获取订单 */
  async getOrderList(params) {
    let result = await client.get(`/order`, { params })
    return result.error ? result : result.data
  },
  /**获取订单 */
  async getOrderInfo(params) {
    let result = await client.get(`/order/${params.orderId}`)
    return result.error ? result : result.data
  },
  /**获取订单状态 */
  async getOrderStatus(params) {
    let result = await client.get(`/order/states`, { params })
    return result.error ? result : result.data
  },
  /**服务商名称列表 */
  async getServiceNames(params) {
    let result = await client.get(`/manufacturer/names`, { params })
    return result.error ? result : result.data
  },
  /**获取服务商打印机 */
  async getPrinters(params) {
    let result = await client.get(`manufacturer/${params.manufacturerId}/printers`, { params })
    return result.error ? result : result.data
  },
  /**获取服务商信息 */
  async getPrinterInfo(params) {
    let result = await client.get(`printer/${params.printerId}`)
    return result.error ? result : result.data
  },
  /**获取工艺列表 */
  async getTechnologyOptions() {
    let result = await client.get(`technology`)
    return result.error ? result : result.data
  },
  /**获取服务商打印机 */
  async getServicersByTechnology(params) {
    let result = await client.get(`manufacturer/page`, { params })
    return result.error ? result : result.data
  },
  /**生成订单请求 */
  async postOrder(params) {
    let result = await client.post(`/order`, params)
    return result.error ? result : result.data
  },
  /**注册打印机 */
  async postPrinter(params) {
    let result = await client.post(`/printer`, params)
    return result.error ? result : result.data
  },
  /**打印机生成绑定码 */
  async getBindCode(params) {
    let result = await client.get(`printer/${params.printerId}/bindcode`)
    return result.error ? result : result.data
  },
  /**启用打印机 */
  async enablePrinter(params) {
    let result = await client.put(`printer/${params.printerId}/enable`)
    return result.error ? result : result.data
  },
  /**禁用打印机 */
  async disablePrinter(params) {
    let result = await client.put(`printer/${params.printerId}/disable`)
    return result.error ? result : result.data
  },
  /**修改打印机名称 */
  async modifyPrinterName(params) {
    let result = await client.put(`printer/${params.printerId}/name?manufacturerId=${params.manufacturerId}&printerName=${params.printerName}`)
    return result.error ? result : result.data
  },
  /**
   * 查询id
   */
  /**查询客户id请求 */
  /**打印机生成绑定码 */
  async getCustomerIdByUsername(params) {
    let result = await client.get(`customer/username/${params.username}`)
    return result.error ? result : result.data
  },
  /**查询服务商id请求 */
  async getServiceIdByUsername(params) {
    let result = await client.get(`manufacturer/username/${params.username}`)
    return result.error ? result : result.data
  },
  /**
   * 注册
   */
  /**客户注册 */
  /**用户注册请求 */
  async postCustomer(params) {
    let result = await client.post(`/customer`, params)
    return result.error ? result : result.data
  },
  /**服务商注册 */
  async postServicer(params) {
    let result = await client.post(`/manufacturer`, params)
    return result.error ? result : result.data
  },
  /**
   * 管理员审核列表
   */
  /**客户列表 */
  async getCustomerList(params) {
    let result = await client.get(`customer/userlist`, { params })
    return result.error ? result : result.data
  },
  /**服务商列表 */
  async getServiceList(params) {
    let result = await client.get(`manufacturer/userlist`, { params })
    return result.error ? result : result.data
  },
  /**通过客户权限 */
  async approvalCustomer(params) {
    let result = await client.put(`customer/${params.username}/approval`)
    return result.error ? result : result.data
  },
  /**禁用客户权限 */
  async disableCustomer(params) {
    let result = await client.put(`customer/${params.username}/disable`)
    return result.error ? result : result.data
  },
  /**通过服务商权限 */
  async approvalServicer(params) {
    let result = await client.put(`manufacturer/${params.username}/approval`)
    return result.error ? result : result.data
  },
  /**禁用服务商权限 */
  async disableServicer(params) {
    let result = await client.put(`manufacturer/${params.username}/disable`)
    return result.error ? result : result.data
  },
  /**
   * 客户：订单操作
   */
  /**提交订单 */
  async submitOrder(params) {
    let result = await client.put(`order/${params.orderId}/submit?customerId=${params.customerId}`)
    return result.error ? result : result.data
  },
  /**取消订单 */
  async cancelOrder(params) {
    let result = await client.put(`order/${params.orderId}/cancel?customerId=${params.customerId}`)
    return result.error ? result : result.data
  },
  /**同意/拒绝延长打印 */
  async confirmDelay(params) {
    let result = await client.put(`order/${params.orderId}/delay/handle?customerId=${params.customerId}&isPass=${params.isPass}`)
    return result.error ? result : result.data
  },
  /**修改打印时限 */
  async delay(params) {
    let result = await client.put(`order/${params.orderId}/customer_delay?customerId=${params.customerId}&deadline=${params.deadline}`)
    return result.error ? result : result.data
  },
  /** 模型：同意/拒绝返工 */
  async confirmRework(params) {
    let result = await client.put(`order/${params.orderId}/models/${params.modelId}/rework/handle?customerId=${params.customerId}&isPass=${params.isPass}`)
    return result.error ? result : result.data
  },
  /**
   * 服务商：订单操作
   */
  /**更新订单 */
  async updateOrder(params) {
    let result = await client.post(`/order/${params.orderId}`, params)
    return result.error ? result : result.data
  },
  /**接收订单 */
  async acceptOrder(params) {
    let result = await client.put(`order/${params.orderId}/accept?manufacturerId=${params.manufacturerId}`)
    return result.error ? result : result.data
  },
  /**申请延长打印 */
  async applyDelay(params) {
    let result = await client.put(`order/${params.orderId}/manufacturer_delay?manufacturerId=${params.manufacturerId}&deadline=${params.deadline}`)
    return result.error ? result : result.data
  },
  /**确认打印完成 */
  async confirmComplete(params) {
    let result = await client.put(`order/${params.orderId}/done?manufacturerId=${params.manufacturerId}`)
    return result.error ? result : result.data
  },
  /** 模型：申请返工 */
  async applyRework(params) {
    let result = await client.put(`order/${params.orderId}/models/${params.modelId}/rework?manufacturerId=${params.manufacturerId}&count=${params.count}`)
    return result.error ? result : result.data
  },
  /**解除挂起模型 */
  async resumeModel(params) {
    let result = await client.put(`order/${params.orderId}/models/${params.modelId}/resume?manufacturerId=${params.manufacturerId}`)
    return result.error ? result : result.data
  },

}

export default $axios