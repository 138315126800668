<template>
  <div class="a-logo-container">
    <router-link class="a-logo-content" to="/">
      <img src="@/assets/logo.png" alt="logo" width="32" height="32" />
      <div
        class="a-logo-title"
        :class="{
          'a-logo-content-light': type === 'Home',
        }"
        data-cy="title"
      >
        <i>Prismlab</i>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.a-logo-container {
  .a-logo-content {
    display: flex;
    align-items: center;
    color: #303133;
    font-size: 1.2rem;
    font-weight: 700;
    .a-logo-title {
      margin-left: 0.5rem;
    }
  }
  .a-logo-content-light {
    color: #fff;
  }
}
</style>
