<template>
  <div class="user-container">
    <a-navbar></a-navbar>
    <el-row>
      <el-col :span="4" style="height: 100%">
        <el-menu :default-active="activeIndex" router>
          <el-menu-item index="/user/order/generate">
            <i class="el-icon-files"></i>
            <span slot="title">新建订单</span>
          </el-menu-item>
          <el-menu-item index="/user/model">
            <i class="el-icon-files"></i>
            <span slot="title">我的模型</span>
          </el-menu-item>
          <el-menu-item index="/user/order">
            <i class="el-icon-shopping-cart-1"></i>
            <span slot="title">我的订单</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="20" class="user-content">
        <router-view></router-view>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import aNavbar from "@/layouts/components/Navbar";
export default {
  data() {
    return {};
  },
  computed: {
    activeIndex() {
      return this.$route.path;
    },
  },
  components: {
    aNavbar,
  },
};
</script>

<style lang="scss">
$nav-height: 48px;

.user-container,
.el-row,
.el-col,
.el-menu {
  height: 100%;
  box-sizing: border-box;
}

.user-container {
  padding-top: $nav-height;
}

.user-content {
  padding: 1rem 2rem;
  box-sizing: border-box;
}

.a-breadcrumb {
  margin: 1rem 0 3rem;
}

.a-add-btn {
  margin-left: 1rem;
}

.a-table {
  padding: 1rem;
}
</style>
