/**
 * 按需引入element-ui
 */
import { Container, Row, Col, Header, Dropdown, DropdownItem, DropdownMenu, Tabs, TabPane, Main, Breadcrumb, BreadcrumbItem, Aside, Menu, MenuItem, Footer, Button, Descriptions, DescriptionsItem, Input, Select, Option, Upload, DatePicker, TimePicker, Form, FormItem, Table, TableColumn, Pagination, Tag, Loading, Dialog, Popconfirm, Popover, Message, MessageBox, Notification, Steps, Step } from 'element-ui'

export default {
  install(Vue) {
    Vue.use(Container)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Header)
    Vue.use(Dropdown)
    Vue.use(DropdownItem)
    Vue.use(DropdownMenu)
    Vue.use(Tabs)
    Vue.use(TabPane)
    Vue.use(Main)
    Vue.use(Breadcrumb)
    Vue.use(BreadcrumbItem)
    Vue.use(Aside)
    Vue.use(Menu)
    Vue.use(MenuItem)
    Vue.use(Footer)
    Vue.use(Button)
    Vue.use(Descriptions)
    Vue.use(DescriptionsItem)
    Vue.use(Input)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Upload)
    Vue.use(DatePicker)
    Vue.use(TimePicker)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Pagination)
    Vue.use(Tag)
    Vue.use(Loading)
    Vue.use(Dialog)
    Vue.use(Popconfirm)
    Vue.use(Popover)
    Vue.use(Steps)
    Vue.use(Step)
    Vue.prototype.$message = Message
    Vue.prototype.$messageBox = MessageBox
    Vue.prototype.$confirm = MessageBox.confirm
    Vue.prototype.$notify = Notification
  }
}