<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "./styles/index.css";
export default {};
</script>
