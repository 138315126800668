/**
 * 角色
 * SuperAdmin: 超级管理员
 * Admin: 管理员
 * ServiceProvider: 服务商
 * Member: 用户
 * Guest: 游客
 */
export default [
  {
    name: "超级管理员",
    value: [
      "customer:operate",
      "customer:add",
      "customer:delete",
      "customer:modify",
      "servicer:operate",
      "servicer:add",
      "servicer:delete",
      "servicer:modify",
      "servicer:generate",
    ]
  },
  {
    name: "管理员",
    value: [
      "customer:operate",
      "customer:add",
      "customer:delete",
      "customer:modify",
      "servicer:operate",
      "servicer:add",
      "servicer:delete",
      "servicer:modify",
      "servicer:generate",
    ]
  }
]