<template>
  <div
    :class="type !== 'Home' ? 'white-backgourd' : ''"
    class="a-nav-container a-flex-center"
  >
    <div class="a-logo">
      <a-logo :type="type"></a-logo>
    </div>
    <div>
      <template v-if="userProfile">
        <el-dropdown @command="handleCommand">
          <span
            class="el-dropdown-link"
            style="color: #303133"
            :class="{
              'a-logo-content-light': type === 'Home',
            }"
          >
            {{ userProfile.name
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="type === 'Home'" command="enter"
              >进入工作区</el-dropdown-item
            >
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template v-else>
        <div v-if="type === 'Home'" @click="handleLogin" class="a-login-btn">
          登录
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import aLogo from "../Logo";
export default {
  name: "Home",
  data() {
    return {
      offsetTops: undefined,
      wheelCount: 1,
      userProfile: undefined,
    };
  },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  mounted() {
    // this.WheelInit();
    this.$axios.client.man.getUser().then((user) => {
      if (user) {
        this.userProfile = user.profile;
        console.log(user.profile);
      } else {
        this.$axios.client.man.removeUser();
      }
    });
  },
  methods: {
    /**登录 */
    handleLogin() {
      this.$axios.client.man.signinRedirect().catch(() => {
        this.$notify({
          type: "error",
          title: "错误",
          message: "服务器尚未连接",
          duration: 0,
        });
      });
    },
    handleCommand(command) {
      if (command === "enter") {
        const role = this.userProfile.role;
        switch (role) {
          case "Member": {
            this.$router.push("/user/order");
            break;
          }
          case "Manufacturer": {
            this.$router.push("/servicer/printer");
            break;
          }
          case "SuperAdmin": {
            this.$router.push("/admin/user");
            break;
          }
          default: {
            // this.$router.push("/home");
            break;
          }
        }
      } else if (command === "logout") {
        this.$axios.client.man.signoutRedirect();
      }
    },
  },
  components: {
    aLogo,
  },
};
</script>

<style lang="scss" scoped>
$nav-height: 48px;
.a-flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.a-nav-container {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $nav-height;
  padding: 0 1rem;
  transform: translateX(-50%);
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  .a-login-btn {
    width: 84px;
    height: $nav-height;
    line-height: $nav-height;
    text-align: center;
    background-color: #007cfc;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #006ad4;
    }
  }
}
.a-logo-content-light {
  color: #fff !important;
}
.white-backgourd {
  background: #fff;
}
</style>
